<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                Santiago Andrés
              </h4><h4 class="">
                Villaquirán Pinzón
              </h4>
              <span class="card-text">Tipo de documento: CC</span><br>
              <span class="card-text">No. documento: 1018416997</span>
            </div>
            
          </div>
        </div>

        
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Correo</span>
            </th>
            <td class="pb-50">
              sanvipi@gmail.com
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Celular</span>
            </th>
            <td class="pb-50 text-capitalize">
              3152623357
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Fecha de nacimiento</span>
            </th>
            <td class="pb-50 text-capitalize">
              21-03-1988
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Lugar de nacimiento</span>
            </th>
            <td class="pb-50 text-capitalize">
              BOGOTA D.C.
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserCheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Edad</span>
            </th>
            <td class="pb-50 text-capitalize">
              35 años
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Pais</span>
            </th>
            <td class="pb-50">
              Colombia
            </td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" xl="6">
        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                $8.000.000
              </h5>
              <small>Ingresos</small>
            </div>
          </div>

        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
